import { filePath } from '../../../utils/media';
import { useTexts } from '../../../utils/texts';
import { Link } from 'react-router-dom';
import { linkPath } from '../../../utils/media';

export const OsallistuBlock = () => {
    const texts = useTexts();

    return (
        <div className="w-full min-h-[calc(100vh-4.5em)] flex flex-row justify-around bg-s-gray">
            <div className="mt-6 w-full flex flex-col justify-start items-center lg:px-36 max-md:px-2">
                <div className="w-full flex flex-row justify-center">
                    <div className="w-full min-h-[18em] relative md:hidden">
                        <img
                            src={filePath('wide_group.png')}
                            className="h-full w-full object-cover select-none rounded-3xl"
                            style={{ objectPosition: '50% 20%' }}
                            draggable="false"
                        />
                    </div>
                    <div className="w-full max-w-[1200px] relative max-md:hidden">
                        <img
                            src={filePath('wide_group.png')}
                            className="h-full w-full object-cover select-none rounded-3xl"
                            style={{ objectPosition: '50% 20%' }}
                            draggable="false"
                        />
                    </div>
                </div>

                <h1 className="w-full mt-16 font-Robotoserif font-bold text-5xl max-md:text-4xl text-s-dgreen text-center">
                    {texts.osallistu.title}
                </h1>

                <div className="my-20 max-lg:px-8 max-lg:w-full lg:w-[80%]">
                    <p className="font-Roboto text-2xl text-s-black">
                        {texts.osallistu.text}
                    </p>
                </div>

                <div className="w-full flex flex-row pt-8 justify-around max-xl:flex-wrap bg-s-gray mb-16">
                    <div className="w-full flex flex-col items-center">
                        <h1 className="w-full mt-8 mb-2 font-Robotoserif font-bold text-5xl max-md:text-3xl text-s-dblue text-center">
                            {texts.osallistu.events}
                        </h1>
                        <p className="font-Roboto mt-2 font-medium text-4xl max-md:text-xl text-s-black">
                            8.3. klo 16. Martti, Hyvinkää
                        </p>
                        <p className="font-Roboto mt-2 font-medium text-4xl max-md:text-xl text-s-black">
                            9.3. klo 16. Paavola, Hyvinkää
                        </p>
                        <p className="font-Roboto mt-2 font-medium text-4xl max-md:text-xl text-s-black">
                            11.3. klo 10. Suurmarkkinat, Lohja
                        </p>
                        <p className="font-Roboto mt-2 font-medium text-4xl max-md:text-xl text-s-black">
                            13.3 klo 18. Paneeli, kirjasto, Siuntio
                        </p>
                        <p className="font-Roboto mt-2 font-medium text-4xl max-md:text-xl text-s-black">
                            15.3 klo 13. Keudan paneeli, Mäntsälä
                        </p>
                        <p className="font-Roboto mt-2 font-medium text-4xl max-md:text-xl text-s-black">
                            16.3 klo 11. Otaniemi, Espoo
                        </p>
                    </div>
                    <div className="p-16 flex-col items-center justify-center hidden">
                        <Link
                            draggable="false"
                            to={linkPath('lahjoita')}
                            target="_blank"
                            className="w-fit flex flex-col justify-center lg:mx-16 max-lg:my-16 px-12 py-8 bg-s-white hover:bg-s-gray active:bg-s-gray/90 transition-colors duration-100"
                        >
                            <h3 className="text-center align-middle text-3xl font-bold font-Roboto text-s-dgreen">
                                {texts.lahjoita}
                            </h3>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};
