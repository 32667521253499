import React from 'react';
import classNames from 'classnames';

/**
 * Social media icon
 */

interface ImgIconProps {
    src: string;
    height: number;
    className?: string;
}

export const ImgIcon: React.FC<ImgIconProps> = ({ src, height, className }) => {
    const h = `${height}em`;

    return (
        <div
            draggable="false"
            style={{ height: h }}
            className={classNames('w-fit', className)}
        >
            <img
                src={src}
                draggable="false"
                className="h-full w-full object-contain"
            />
        </div>
    );
};
