import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Arvot,
    CV,
    Lapsuus,
    SanteriInfo,
    SanteriPast,
} from '../../components/Santeri';
import { useTexts } from '../../utils/texts';
import { useAppDispatch } from '../../store';
import { addClick } from '../../reducers/clickReducer';

export const Santeri = () => {
    const texts = useTexts();

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(addClick('click-santeri'));
    }, []);

    return (
        <div className="">
            <Helmet>
                <title>{texts.santeri.title}</title>
            </Helmet>
            <h1 className="w-full h-full bg-s-gray py-16 px-8 font-Robotoserif font-bold text-6xl max-md:text-5xl text-s-dgreen text-center">
                {texts.santeri.title}
            </h1>
            <SanteriInfo />
            <Lapsuus />
            <Arvot />
            <CV />
            <SanteriPast />
        </div>
    );
};
