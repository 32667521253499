import { filePath } from '../../../utils/media';
import { useTexts } from '../../../utils/texts';

export const FirstBlock = () => {
    const texts = useTexts();

    return (
        <div className="w-full min-h-[calc(100vh-4.5em)] flex flex-row justify-around bg-s-gray">
            <div className="w-full flex flex-col justify-start lg:px-36 max-md:px-2">
                <div className="w-full flex flex-row justify-center">
                    <div className="w-full h-[calc(100vh-20em)] min-h-[26em] relative md:hidden">
                        <img
                            src={filePath('narrow_train.png')}
                            className="h-full w-full object-cover select-none rounded-3xl"
                            style={{ objectPosition: '0% 30%' }}
                            draggable="false"
                        />
                    </div>
                    <div className="w-full h-[calc(100vh-24em)] min-h-[26em] relative max-md:hidden">
                        <img
                            src={filePath('wide_bridge.png')}
                            className="h-full w-full object-cover select-none rounded-3xl"
                            style={{ objectPosition: '50% 20%' }}
                            draggable="false"
                        />
                    </div>
                </div>
                <div className="mt-20 md:mt-12 max-lg:px-8 xl:pr-80">
                    <h3 className="font-Roboto text-2xl text-s-black font-medium">
                        {texts.vaali.part1.p1.title}{' '}
                    </h3>
                    <p className="font-Roboto pt-2 leading-normal font-light text-2xl text-s-black">
                        {texts.vaali.part1.p1.text}
                    </p>
                </div>
                <div className="mt-16 max-lg:px-8 xl:pr-80">
                    <h3 className="font-Roboto text-2xl text-s-black font-medium">
                        {texts.vaali.part1.p2.title}{' '}
                    </h3>
                    <p className="font-Roboto pt-2 leading-normal font-light text-2xl text-s-black">
                        {texts.vaali.part1.p2.text}
                    </p>
                </div>

                <div className="mt-16 mb-8 max-lg:px-8 xl:pr-80">
                    <h3 className="font-Roboto text-2xl text-s-black font-medium">
                        {texts.vaali.part1.p3.title}{' '}
                    </h3>
                    <p className="font-Roboto pt-2 leading-normal font-light text-2xl text-s-black">
                        {texts.vaali.part1.p3.text1}
                    </p>
                </div>

                <div className="mb-8 max-lg:px-8 xl:pr-80">
                    <p className="font-Roboto pt-2 leading-normal font-light text-2xl text-s-black">
                        {texts.vaali.part1.p3.text2}
                    </p>
                </div>
            </div>
            <img
                src={filePath('narrow_train.png')}
                className="h-[calc(100vh-16em)] rounded-3xl min-h-[30em] m-16 w-auto hidden"
            />
        </div>
    );
};
