import { filePath } from '../../../../utils/media';
import { useTexts } from '../../../../utils/texts';
import { ExpandableParagraph } from '../../../ExpandableParagraph';

export const TurvaBlock = () => {
    const texts = useTexts();

    return (
        <div
            id="i1"
            className="w-full min-h-[calc(100vh-4.5em)] flex flex-col items-center bg-s-gray"
        >
            <div className="flex flex-row justify-between items-center mt-20">
                <img
                    className="m-4 h-[4.5em] w-[4.5em] max-md:h-[4em] max-md:w-[4em]"
                    src={filePath('earth_g.svg')}
                />
                <div className="p-4">
                    <h3 className="text-3xl max-md:text-2xl font-medium text-s-dgreen font-Roboto">
                        {texts.vaali.turva.motto1}
                    </h3>
                    <h4 className="text-3xl max-md:text-2xl font-light text-s-dblue font-Roboto">
                        {texts.vaali.turva.motto2}
                    </h4>
                </div>
            </div>
            <div className="max-lg:w-full lg:w-[60%]">
                <p className="px-8 mt-16 text-xl lg:text-2xl font-normal font-Roboto text-s-black">
                    {texts.vaali.turva.introtext.p1}
                </p>
                <p className="px-8 mt-16 text-xl lg:text-2xl font-normal font-Roboto text-s-black">
                    {texts.vaali.turva.introtext.p2}
                </p>

                <div className="w-full flex flex-col items-center">
                    <ExpandableParagraph title={texts.vaali.turva.rus.title}>
                        <div>
                            <p className="px-10 pb-6 leading-normal text-xl lg:text-2xl font-normal font-Robotoserif text-s-black">
                                •{' '}
                                <strong className="font-medium">
                                    {texts.vaali.turva.rus.p1.b1}
                                </strong>{' '}
                                {texts.vaali.turva.rus.p1.t1}
                            </p>
                            <p className="px-10 pb-6 leading-normal text-xl lg:text-2xl font-normal font-Robotoserif text-s-black">
                                •{' '}
                                <strong className="font-medium">
                                    {texts.vaali.turva.rus.p2.b1}
                                </strong>{' '}
                                {texts.vaali.turva.rus.p2.t1}
                            </p>
                            <p className="px-10 pb-6 leading-normal text-xl lg:text-2xl font-normal font-Robotoserif text-s-black">
                                • {texts.vaali.turva.rus.p3.t1}
                            </p>
                            <p className="px-10 pb-6 leading-normal text-xl lg:text-2xl font-normal font-Robotoserif text-s-black">
                                •{' '}
                                <strong className="font-medium">
                                    {texts.vaali.turva.rus.p4.b1}
                                </strong>{' '}
                                {texts.vaali.turva.rus.p4.t1}
                            </p>
                            <p className="px-10 pb-6 leading-normal text-xl lg:text-2xl font-normal font-Robotoserif text-s-black">
                                • {texts.vaali.turva.rus.p5.t1}
                            </p>
                            <p className="px-10 pb-6 leading-normal text-xl lg:text-2xl font-normal font-Robotoserif text-s-black">
                                • {texts.vaali.turva.rus.p6.t1}
                            </p>
                            <p className="px-10 pb-6 leading-normal text-xl lg:text-2xl font-normal font-Robotoserif text-s-black">
                                • {texts.vaali.turva.rus.p7.t1}
                            </p>
                        </div>
                    </ExpandableParagraph>
                </div>
            </div>
        </div>
    );
};
