import { useTexts } from '../../../utils/texts';

export const Arvot = () => {
    const texts = useTexts();

    return (
        <div className="w-full flex flex-row justify-center bg-s-gray py-16">
            <div className="bg-s-dgreen xl:px-24 max-xl:px-12 py-8 xl:min-w-[60%] h-fit w-fit xl:rounded-3xl max-xl:w-full text-s-white">
                <h2 className="font-Roboto text-center text-4xl max-sm:text-3xl text-s-bgreen pb-8">
                    {texts.santeri.arvot.title}
                </h2>
                <p className="font-Roboto py-2 text-xl">
                    <strong>{texts.santeri.arvot.i1.title}</strong>
                    {texts.santeri.arvot.i1.text}
                </p>
                <p className="font-Roboto py-2 text-xl">
                    <strong>{texts.santeri.arvot.i2.title}</strong>
                    {texts.santeri.arvot.i2.text}
                </p>
                <p className="font-Roboto py-2 text-xl">
                    <strong>{texts.santeri.arvot.i3.title}</strong>
                    {texts.santeri.arvot.i3.text}
                </p>
                <p className="font-Roboto py-2 text-xl">
                    <strong>{texts.santeri.arvot.i4.title}</strong>
                    {texts.santeri.arvot.i4.text}
                </p>
                <p className="font-Roboto py-2 text-xl">
                    <strong>{texts.santeri.arvot.i5.title}</strong>
                    {texts.santeri.arvot.i5.text}
                </p>
            </div>
        </div>
    );
};
