import React from 'react';
import { Images, filePath } from '../../../utils/media';

/**
 * Fullscreen banner image the size of one block
 */

interface BannerImageProps {
    img: Images;
    children?: React.ReactNode;
}

export const BannerImage: React.FC<BannerImageProps> = ({ img, children }) => {
    return (
        <div className="w-full h-[calc(100vh-4.5em)] max-xl:min-h-[53em] max-2xl:min-h-[41em] 2xl:min-h-[54em] relative">
            <img
                src={filePath(img)}
                className="h-full w-full object-cover select-none"
                draggable="false"
                style={{ objectPosition: '63% 0%' }}
            />
            {children}
        </div>
    );
};
