import { Link } from 'react-router-dom';
import { SocialUrls } from '../../../utils/media';
import { useTexts } from '../../../utils/texts';

export const MediaEmbed = () => {
    //Try to initialize FB javascript sdk
    try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).FB.init({ cookie: true, xfbml: true, version: 'v8.0' });
    } catch (err) {
        console.log(err);
    }

    const texts = useTexts();

    return (
        <div className="w-full min-h-[calc(100vh-4.5em)] flex flex-row justify-around bg-s-gray mb-8">
            <div className="w-full flex flex-col justify-center">
                <div className="h-full flex flex-col items-center justify-start">
                    <h1 className="font-Robotoserif py-20 px-4 text-center font-bold max-sm:text-4xl max-xl:text-5xl text-6xl text-s-dblue">
                        {texts.home.mediatitle}
                    </h1>
                    <div className="w-full flex flex-row justify-center relative">
                        <div className="absolute top-20 left-0 w-full flex flex-col items-center">
                            <p className="text-s-black font-Roboto text-2xl">
                                Unable to load Facebook feed
                            </p>
                            <i className="pt-5">
                                <Link
                                    to={SocialUrls['facebook']}
                                    target="_blank"
                                    className="hover:text-s-green font-Robotoserif text-s-dblue text-3xl"
                                >
                                    Visit the page here
                                </Link>
                            </i>
                        </div>
                        <div
                            className="fb-page"
                            data-tabs="timeline"
                            data-href={SocialUrls['facebook']}
                            data-height="700"
                            data-width=""
                            data-adapt-container-width="true"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    );
};
