import React from 'react';

interface BannerTextProps {
    text: string;
    fontSize: number;
    className?: string;
}

export const BannerText: React.FC<BannerTextProps> = ({
    text,
    fontSize,
    className,
}) => {
    return (
        <svg
            className={className}
            style={{ fontSize: fontSize, height: fontSize * 1.3 }}
        >
            <text
                y={fontSize}
                width={20}
                className="stroke-[3] stroke-s-gray/20 select-none"
            >
                {text}
            </text>
            <text y={fontSize} width={20} className="fill-s-black">
                {text}
            </text>
        </svg>
    );
};
