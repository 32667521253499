import { filePath } from '../../../utils/media';
import { useTexts } from '../../../utils/texts';

export const SanteriPast = () => {
    const texts = useTexts();

    return (
        <div className="w-full min-h-[calc(100vh-4.5em)] flex flex-row justify-around pt-8 md:pb-16 bg-s-gray">
            <img
                src={filePath('narrow_accordion_fade.png')}
                className="h-[calc(100vh-20em)] rounded-3xl min-h-[30em] w-auto ml-14 max-2xl:hidden"
            />
            <div className="w-full flex flex-col justify-start lg:px-36">
                <div className="mt-8 max-lg:px-8">
                    <h3 className="font-Roboto text-2xl text-s-black font-medium">
                        {texts.santeri.part2.p1.title}{' '}
                    </h3>
                    <p className="font-Roboto pt-2 leading-normal font-light text-2xl text-s-black">
                        {texts.santeri.part2.p1.text}
                    </p>
                </div>
                <div className="mt-16 mb-8 max-lg:px-8">
                    <h3 className="font-Roboto text-2xl text-s-black font-medium">
                        {texts.santeri.part2.p2.title}{' '}
                    </h3>
                    <p className="font-Roboto pt-2 leading-normal font-light text-2xl text-s-black">
                        {texts.santeri.part2.p2.text}
                    </p>
                </div>
                <div className="max-lg:px-8">
                    <p className="font-Roboto pt-2 leading-normal font-light text-2xl text-s-black">
                        {texts.santeri.part2.p3.text}
                    </p>
                </div>

                <div className="w-full flex flex-row justify-center 2xl:hidden mt-10">
                    <div className="w-full h-[calc(100vh-20em)] min-h-[36em] relative">
                        <img
                            src={filePath('narrow_accordion.png')}
                            className="h-full w-full object-cover select-none rounded-3xl md:hidden"
                            style={{ objectPosition: '30% 10%' }}
                            draggable="false"
                        />
                        <img
                            src={filePath('wide_lightstick.png')}
                            className="h-full w-full object-cover select-none rounded-3xl max-md:hidden"
                            style={{ objectPosition: '30% 10%' }}
                            draggable="false"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
