import { SocialIcon } from '../../../../Icons';

/**
 * Mini actionbar
 */

export const SmallActionBar = () => {
    return (
        <div className="relative w-full h-36 overflow-hidden">
            <div className="w-full flex flex-row justify-around px-2 items-center select-none absolute top-0 left-0">
                <SocialIcon social="instagram" height={2} />
                <SocialIcon social="facebook" height={2} />
                <SocialIcon social="twitter" height={2} />
                <SocialIcon social="youtube" height={2} />
                <SocialIcon social="tiktok" height={2} />
            </div>
        </div>
    );
};
