import { configureStore } from '@reduxjs/toolkit';
import langReducer from './reducers/langReducer';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import clickReducer from './reducers/clickReducer';

const store = configureStore({
    reducer: {
        lang: langReducer,
        click: clickReducer,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
type RootState = ReturnType<typeof store.getState>;
// Inferred type:
type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export { store };
