import { Route, Routes as Switch } from 'react-router-dom';
import { Home } from './pages/Home';
import { Santeri } from './pages/Santeri';
import { BlogPage } from './pages/Blogi';
import { useEffect } from 'react';
import { Vaaliohjelma } from './pages/Vaaliohjelma';
import { Osallistu } from './pages/Osallistu';

/**
 *  Routes of the application
 */

/* Route rest of the pages to Joomla */
const Rerouter = () => {
    useEffect(() => {
        location.reload();
    });
    return <div></div>;
};

export const Routes = () => {
    return (
        <Switch>
            <Route path="/santeri" element={<Santeri />} />
            <Route path="/vaaliohjelma" element={<Vaaliohjelma />} />
            <Route path="/osallistu" element={<Osallistu />} />
            {/*<Route path="/blogi" element={<BlogPage />} />*/}
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Rerouter />} />
        </Switch>
    );
};
