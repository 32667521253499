import { NavItem } from './NavItem';
import { NavDropdown } from './NavDropdown';
import { linkPath } from '../../../../utils/media';
import { useTexts } from '../../../../utils/texts';

/**
 * List of navigation options
 */

export const NavList = () => {
    const texts = useTexts();

    return (
        <div className="w-full flex flex-row justify-end max-xl:hidden">
            <NavItem name={texts.nav.santeri} path="/santeri" />
            {
                <NavDropdown
                    name={texts.nav.vaalit}
                    items={[
                        { name: texts.nav.vaalit1, path: '/vaaliohjelma' },
                        //{ name: 'Politiikkani A—Ö', path: 'politiikkani' },
                        //{ name: 'Vaalilehti', path: 'vaalilehti' },
                        { name: texts.nav.vaalit2, path: '/osallistu' },
                        //{ name: 'Santerin villapaitapeli', path: 'villapaitapeli' },
                    ]}
                />
            }

            <NavDropdown
                name={texts.nav.kunta}
                items={[
                    { name: texts.nav.kunta1, path: '/tavoitteeni' },
                    { name: texts.nav.kunta2, path: '/valtuustotyo' },
                ]}
            />
            <NavItem name={texts.nav.blogi} path="/blogi" />
            <NavItem name={texts.nav.yhteys} path="/otayhteytta" />
            <NavItem
                name={texts.lahjoita}
                path={linkPath('lahjoita')}
                noParams={true}
                target="_blank"
                className="h-full w-fit flex flex-col justify-center px-10 bg-s-bgreen hover:bg-s-gray active:bg-s-gray/90 transition-colors duration-100"
                innerClassName="text-center align-middle text-2xl font-bold font-Roboto text-s-dgreen"
            />
        </div>
    );
};
