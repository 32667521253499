import { Routes } from './Routes';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import { Footer, NavigationContainer } from './components';
import { ScrollToTop } from './ScrollToTop';
import { LangSelector } from './LangSelector';

/**
 * The base react component
 * Contains headers, page background and
 * Page title. Used to control the top level
 * layout of the page.
 */
function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <ScrollToTop />
                <LangSelector />
                <NavigationContainer>
                    <Routes />
                    <Footer />
                </NavigationContainer>
            </BrowserRouter>
        </Provider>
    );
}

export { App };
