import React from 'react';
import { Link } from 'react-router-dom';
import { GreenIcon, SanteriIcon } from '../Icons';
import { linkPath } from '../../utils/media';
import { useTexts } from '../../utils/texts';

/**
 * Footer with links
 */

export const Footer = () => {
    const texts = useTexts();

    return (
        <footer className="w-full min-h-[calc(100vh-16em)] bg-s-dgreen flex flex-col justify-end items-center">
            <div className="w-full h-full flex flex-row justify-between"></div>
            {/*<div className="font-Robotoserif text-lg w-full p-4 text-s-white">
                <p>
                    Valokuvat: Jussi Leinonen, Tuomo Manninen, Hanna Tarkiainen,
                    Iida Tukeva
                </p>
                <p className="mt-4">Tekninen toteutus: Tommi Korpelainen</p>
                <p>© 2021 Santeri Leinonen</p>
            </div>*/}
            <div className="w-full flex lg:flex-row max-lg:flex-col justify-between items-center m-8 lg:px-32">
                <GreenIcon height={4.5} logo="logo_grn_white.svg" />
                <Link
                    draggable="false"
                    to={linkPath('lahjoita')}
                    target="_blank"
                    className="w-fit flex flex-col justify-center lg:mx-16 max-lg:my-16 px-12 py-8 bg-s-white hover:bg-s-gray active:bg-s-gray/90 transition-colors duration-100"
                >
                    <h3 className="text-center align-middle text-3xl font-bold font-Roboto text-s-dgreen">
                        {texts.lahjoita}
                    </h3>
                </Link>
                <SanteriIcon height={5.5} logo="slogo_white.svg" />
            </div>
        </footer>
    );
};
