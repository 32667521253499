import React, { useState } from 'react';
import { filePath } from '../../../../utils/media';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { useAppSelector, useAppDispatch } from '../../../../store';
import { Transition } from '@headlessui/react';
import { setLang } from '../../../../reducers/langReducer';
import { useSearchParams } from 'react-router-dom';

export const LangMenu = () => {
    const dispatch = useAppDispatch();
    const lang = useAppSelector((state) => state.lang.lang);
    const [open, setOpen] = useState(false);

    const [, setParams] = useSearchParams();

    const setL = (lang?: string) => {
        setParams((p) => {
            if (lang) p.set('lang', lang);
            else p.delete('lang');
            return p;
        });
        dispatch(setLang(lang));
    };

    return (
        <div className="absolute right-0 top-[4.5em] flex flex-col">
            <div
                className="flex flex-row justify-center items-center cursor-pointer hover:bg-s-bblue/40 mr-2 mt-2 rounded-3xl transition-colors"
                onClick={() => setOpen(!open)}
            >
                <img
                    src={filePath(lang ? 'flag_sv.png' : 'flag_fi.png')}
                    className="h-6 w-auto my-5 mx-4"
                />
                {open ? (
                    <ChevronUpIcon className="w-6 h-6 my-5 mr-6" />
                ) : (
                    <ChevronDownIcon className="w-6 h-6 my-5 mr-6" />
                )}
            </div>
            <Transition
                show={open}
                unmount={false}
                enterFrom="opacity-0 -translate-y-5"
                enterTo="opacity-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0 -translate-y-5"
                className="flex flex-row cursor-pointer mr-2 mt-2 rounded-3xl transition-all duration-200 hover:bg-s-bblue/40"
                onClick={() => {
                    if (lang) setL(undefined);
                    else setL('sv');
                    setOpen(!open);
                }}
            >
                <img
                    src={filePath(lang ? 'flag_fi.png' : 'flag_sv.png')}
                    className="h-6 w-auto my-5 mx-4"
                />
            </Transition>
        </div>
    );
};
