import { Link, useLocation } from 'react-router-dom';
import { linkPath, filePath } from '../../../utils/media';
import { useTexts } from '../../../utils/texts';

export const KukaSanteri = () => {
    const texts = useTexts();
    const loc = useLocation();

    return (
        <div className="w-full min-h-[calc(100vh-4.5em)] flex flex-row justify-around bg-s-gray">
            <div className="w-full flex flex-col justify-between">
                <div className="pl-24 py-10 h-full flex flex-col justify-center max-xl:px-8">
                    <h1 className="font-Robotoserif font-bold max-xl:text-5xl text-6xl max-sm:text-4xl text-s-dgreen">
                        {texts.home.kuka.title}
                    </h1>
                    <p className="font-Roboto text-2xl max-sm:text-xl text-s-black pt-14">
                        {texts.home.kuka.p1}
                    </p>
                    <p className="font-Roboto text-2xl max-sm:text-xl text-s-black pt-5">
                        {texts.home.kuka.p2}
                    </p>
                    <p className="font-Roboto text-2xl max-sm:text-xl text-s-black pt-5">
                        {texts.home.kuka.p3}
                    </p>
                    <i className="pt-5">
                        <Link
                            to={{ pathname: '/santeri', search: loc.search }}
                            className="hover:text-s-green font-Robotoserif text-s-dblue text-3xl max-sm:text-2xl"
                        >
                            {texts.home.kuka.link}
                        </Link>
                    </i>
                </div>

                <div className="bg-s-dblue xl:px-24 max-xl:px-12 py-8 xl:min-w-[60%] h-fit w-fit xl:rounded-r-3xl max-xl:w-full">
                    <h3 className="font-Roboto text-2xl max-sm:text-xl font-bold text-s-bgreen">
                        {texts.home.kuka.uutis.title}
                    </h3>
                    <br />
                    <p className="font-Roboto text-2xl max-sm:text-xl text-s-bgreen">
                        {texts.home.kuka.uutis.text}
                    </p>
                    <br />
                    <Link
                        to={linkPath('uutiskirje')}
                        target="_blank"
                        className="h-[3.5em] w-fit px-20 max-sm:px-10 bg-s-bgreen hover:bg-s-gray active:bg-s-gray/90 transition-colors duration-100 rounded-xl flex items-center justify-center"
                    >
                        <i className="font-Robotoserif text-s-black max-xl:text-2xl text-3xl max-sm:text-lg">
                            {texts.home.kuka.uutis.link}
                        </i>
                    </Link>
                </div>
            </div>
            <img
                src={filePath('drawing_black.svg')}
                className="h-[calc(100vh-6em)] min-h-[40em] w-auto p-20 max-xl:hidden"
            />
        </div>
    );
};
