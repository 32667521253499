import { InfoBox } from './InfoBox';
import { ImgIcon } from '../../Icons';
import { useTexts } from '../../../utils/texts';

export const Vaaliteemani = () => {
    const texts = useTexts();

    const icon1 = <ImgIcon src="files/earth_w.svg" height={4} />;
    const icon2 = <ImgIcon src="files/lehti_w.png" height={4} />;
    const icon3 = (
        <div className="w-[4em] h-[4em] cursor-default flex flex-row justify-center items-center">
            <p className=" font-Roboto font-bold text-s-white text-6xl">€</p>
        </div>
    );
    const icon4 = <ImgIcon src="files/lapsi_vanhus_w.png" height={4} />;

    return (
        <div className="w-full min-h-[calc(100vh-4.5em)] flex flex-row justify-around bg-s-gray">
            <div className="w-full flex flex-col justify-between">
                <div className="px-8 h-full flex flex-col justify-start">
                    <h1 className="font-Robotoserif py-20 text-center font-bold max-xl:text-5xl text-6xl max-sm:text-4xl text-s-dblue">
                        {texts.home.teemat.title}
                    </h1>
                    <div className="flex flex-row justify-between max-2xl:hidden">
                        <InfoBox
                            ml="0.5em"
                            mr="0.5em"
                            {...texts.home.teemat.teema1}
                            linkPath="/vaaliohjelma#i1"
                            icon={icon1}
                        />
                        <InfoBox
                            ml="0.5em"
                            mr="0.5em"
                            {...texts.home.teemat.teema2}
                            linkPath="/vaaliohjelma#i2"
                            icon={icon2}
                        />
                        <InfoBox
                            ml="0.5em"
                            mr="0.5em"
                            {...texts.home.teemat.teema3}
                            linkPath="/vaaliohjelma#i3"
                            icon={icon3}
                        />
                        <InfoBox
                            ml="0.5em"
                            mr="0.5em"
                            {...texts.home.teemat.teema4}
                            linkPath="/vaaliohjelma#i4"
                            icon={icon4}
                        />
                    </div>
                    <div className="flex flex-col items-center max-xl:hidden 2xl:hidden">
                        <div className="w-full flex flex-row justify-center">
                            <InfoBox
                                ml="1em"
                                mr="1em"
                                {...texts.home.teemat.teema1}
                                linkPath="/vaaliohjelma#i1"
                                icon={icon1}
                            />
                            <InfoBox
                                ml="1em"
                                mr="1em"
                                {...texts.home.teemat.teema2}
                                linkPath="/vaaliohjelma#i2"
                                icon={icon2}
                            />
                        </div>
                        <div className="w-full flex flex-row justify-center">
                            <InfoBox
                                ml="1em"
                                mr="1em"
                                {...texts.home.teemat.teema3}
                                linkPath="/vaaliohjelma#i3"
                                icon={icon3}
                            />
                            <InfoBox
                                ml="1em"
                                mr="1em"
                                {...texts.home.teemat.teema4}
                                linkPath="/vaaliohjelma#i4"
                                icon={icon4}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col items-center xl:hidden">
                        <InfoBox
                            ml="0.25em"
                            mr="0.25em"
                            {...texts.home.teemat.teema1}
                            linkPath="/vaaliohjelma#i1"
                            icon={icon1}
                        />
                        <InfoBox
                            ml="0.25em"
                            mr="0.25em"
                            {...texts.home.teemat.teema2}
                            linkPath="/vaaliohjelma#i2"
                            icon={icon2}
                        />
                        <InfoBox
                            ml="0.25em"
                            mr="0.25em"
                            {...texts.home.teemat.teema3}
                            linkPath="/vaaliohjelma#i3"
                            icon={icon3}
                        />
                        <InfoBox
                            ml="0.25em"
                            mr="0.25em"
                            {...texts.home.teemat.teema4}
                            linkPath="/vaaliohjelma#i4"
                            icon={icon4}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
