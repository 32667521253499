import { useState, useRef, useEffect } from 'react';
import { Bars3Icon } from '@heroicons/react/24/solid';
import { Transition } from '@headlessui/react';
import { NavItem } from './NavItem';
import { NavDropdown } from './NavDropdown';
import { linkPath } from '../../../../utils/media';
import { useTexts } from '../../../../utils/texts';

export const NavDrawer = () => {
    const [open, setOpen] = useState(false);
    const [d1Open, d1SetOpen] = useState(false);
    const [d2Open, d2SetOpen] = useState(false);

    const openAll = () => {
        setOpen(true);
        document.body.className = 'max-xl:overflow-hidden';
    };

    const closeAll = () => {
        setOpen(false);
        d1SetOpen(false);
        d2SetOpen(false);
        document.body.className = '';
    };

    const d1Set = (b: boolean) => {
        d2SetOpen(false);
        d1SetOpen(b);
    };

    const d2Set = (b: boolean) => {
        d1SetOpen(false);
        d2SetOpen(b);
    };

    const drawerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const close = (e: MouseEvent) => {
            if (!drawerRef.current?.contains(e.target as Node)) {
                closeAll();
            }
        };

        document.addEventListener('mousedown', close);
        return () => {
            document.removeEventListener('mousedown', close);
            document.body.className = '';
        };
    }, []);

    const texts = useTexts();

    return (
        <div className="w-full flex flex-row justify-end xl:hidden">
            <div
                className="h-full w-fit flex flex-col justify-center px-8 hover:bg-s-white/5 active:bg-s-white/10 cursor-pointer transition-colors duration-100"
                onClick={openAll}
            >
                <Bars3Icon className="w-8 h-8 text-s-white" />
            </div>
            <Transition
                show={open}
                unmount={false}
                enter="transition-all duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-all duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="z-20 absolute w-screen h-screen bg-s-black/30"
            >
                <Transition
                    ref={drawerRef}
                    show={open}
                    unmount={false}
                    enter="transition-all duration-200"
                    enterFrom="opacity-0 translate-x-20"
                    enterTo="opacity-100 translate-x-0"
                    leave="transition-all duration-200"
                    leaveFrom="opacity-100 translate-x-0"
                    leaveTo="opacity-0 translate-x-20"
                    className="z-30 fixed top-0 right-0 h-full"
                >
                    {
                        <div className="w-[300px] h-full z-30 bg-s-dgreen shadow-s-black shadow-2xl">
                            <div className="h-full w-full overflow-y-auto">
                                <div className="h-[4.5em] flex flex-row justify-between items-center shadow-s-black/5 shadow-xl">
                                    <NavItem
                                        name={texts.lahjoita}
                                        path={linkPath('lahjoita')}
                                        noParams={true}
                                        target="_blank"
                                        closeAll={closeAll}
                                        className="h-full w-full flex flex-col justify-center px-10 bg-s-bgreen hover:bg-s-gray active:bg-s-gray/90 transition-colors duration-100"
                                        innerClassName="text-center align-middle text-2xl font-bold font-Roboto text-s-dgreen"
                                    />
                                    <div
                                        className="h-full w-fit flex flex-col justify-center px-12 hover:bg-s-white/5 active:bg-s-white/10 cursor-pointer transition-colors duration-100"
                                        onClick={closeAll}
                                    >
                                        <Bars3Icon className="w-8 h-8 text-s-white" />
                                    </div>
                                </div>
                                <NavItem
                                    name={texts.nav.santeri}
                                    path="/santeri"
                                    closeAll={closeAll}
                                />
                                {
                                    <NavDropdown
                                        open={d1Open}
                                        setOpen={d1Set}
                                        name={texts.nav.vaalit}
                                        closeAll={closeAll}
                                        items={[
                                            {
                                                name: texts.nav.vaalit1,
                                                path: 'vaaliohjelma',
                                            },
                                            //{
                                            //    name: 'Politiikkani A—Ö',
                                            //    path: 'politiikkani',
                                            //},
                                            //{
                                            //    name: 'Vaalilehti',
                                            //    path: 'vaalilehti',
                                            //},
                                            {
                                                name: texts.nav.vaalit2,
                                                path: 'osallistu',
                                            },
                                            //{
                                            //    name: 'Santerin villapaitapeli',
                                            //    path: 'villapaitapeli',
                                            //},
                                        ]}
                                    />
                                }
                                <NavDropdown
                                    open={d2Open}
                                    setOpen={d2Set}
                                    closeAll={closeAll}
                                    name={texts.nav.kunta}
                                    items={[
                                        {
                                            name: texts.nav.kunta1,
                                            path: 'tavoitteeni',
                                        },
                                        {
                                            name: texts.nav.kunta2,
                                            path: 'valtuustotyo',
                                        },
                                    ]}
                                />
                                <NavItem
                                    name={texts.nav.blogi}
                                    path="/blogi"
                                    closeAll={closeAll}
                                />
                                <NavItem
                                    name={texts.nav.yhteys}
                                    path="/otayhteytta"
                                    closeAll={closeAll}
                                />
                            </div>
                        </div>
                    }
                </Transition>
            </Transition>
        </div>
    );
};
