import { filePath } from '../../../../utils/media';
import { useTexts } from '../../../../utils/texts';

export const TalousBlock = () => {
    const texts = useTexts();

    return (
        <div
            id="i3"
            className="w-full min-h-[calc(100vh-4.5em)] flex flex-col items-center bg-s-gray"
        >
            <div className="flex flex-row justify-between items-center mt-20">
                <img
                    className="m-4 h-[4.5em] max-md:h-[4em]"
                    src={filePath('lapsi_vanhus_g.png')}
                />
                <div className="p-4">
                    <h3 className="text-3xl max-md:text-2xl font-medium text-s-dgreen font-Roboto">
                        {texts.vaali.talous.motto1}
                    </h3>
                    <h4 className="text-3xl max-md:text-2xl font-light text-s-dblue font-Roboto">
                        {texts.vaali.talous.motto2}
                    </h4>
                </div>
            </div>
            <div className="max-lg:w-full lg:w-[60%]">
                <p className="px-8 mt-16 text-xl lg:text-2xl font-normal font-Roboto text-s-black">
                    {texts.vaali.talous.introtext.p1}
                </p>
                <p className="px-8 mt-16 text-xl lg:text-2xl font-normal font-Roboto text-s-black">
                    {texts.vaali.talous.introtext.p2}
                </p>
            </div>
        </div>
    );
};
