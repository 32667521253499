import { useTexts } from '../../../utils/texts';
import { VaaliPoint } from './VaaliPoint';

export const VaaliPoints = () => {
    const texts = useTexts();

    return (
        <div className="w-full flex flex-col justify-center bg-s-gray pt-3 pb-16">
            <h3 className="w-full pt-20 font-Roboto font-medium text-3xl max-md:text-2xl max-sm:text-xl text-s-dgreen text-center">
                {texts.vaali.vaalipoints.title}
            </h3>
            <div className="w-full flex flex-row flex-wrap justify-center px-20 mt-10">
                <VaaliPoint
                    linkPath="#i1"
                    linkText={texts.vaali.vaalipoints.i1}
                />
                <VaaliPoint
                    linkPath="/vaaliohjelma#i3"
                    linkText={texts.vaali.vaalipoints.i2}
                />
                <VaaliPoint
                    linkPath="#i2"
                    linkText={texts.vaali.vaalipoints.i3}
                />
                <VaaliPoint
                    linkPath="#i4"
                    linkText={texts.vaali.vaalipoints.i4}
                />
                <VaaliPoint
                    linkPath="#i5"
                    linkText={texts.vaali.vaalipoints.i5}
                />
            </div>
        </div>
    );
};
