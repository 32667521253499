import React from 'react';
import { Link } from 'react-router-dom';

export interface Article {
    id: number;
    title: string;
    desc: string;
    created: string;
    tags: { id: number; name: string }[];
}

export const BlogListing: React.FC<Article> = ({
    id,
    title,
    desc,
    created,
    tags,
}) => {
    return (
        <div className="max-md:w-full md:w-3/5 xl:w-[600px] h-fit bg-s-bgreen rounded-3xl px-12 py-6 mb-16 shadow-lg shadow-s-black/10">
            <h3 className="text-2xl text-left font-Robotoserif font-bold pt-2 text-s-dgreen">
                {title}
            </h3>
            <p className="text-xl text-left font-Roboto font-light pt-4 text-s-black">
                {desc}
            </p>
            <div className="flex flex-row justify-between pt-8 flex-wrap">
                <i>
                    <Link
                        to={`/artikkelit?artikkeli=${id}`}
                        className="text-xl mr-6 text-s-dblue hover:text-s-black font-Robotoserif"
                    >
                        Lue lisää
                    </Link>
                </i>
                <p className="text-md text-left font-Robotoserif text-s-black">
                    Julkaistu {created}
                </p>
            </div>
            <div className="flex flex-row justify-end pt-2 flex-wrap hidden">
                {tags.length > 1 && (
                    <p className="text-lg font-Roboto text-s-dgreen">tagit: </p>
                )}
                {tags.map((t) => {
                    return (
                        <p
                            key={t.id}
                            className="pl-2 text-lg font-Roboto text-s-dgreen"
                        >
                            {t.name}
                        </p>
                    );
                })}
            </div>
        </div>
    );
};
