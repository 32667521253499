import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ClickState {
    clicks: string[];
}

const initialState: ClickState = {
    clicks: [],
};

export const clickSlice = createSlice({
    name: 'click',
    initialState,
    reducers: {
        addClick(state, action: PayloadAction<string>) {
            state.clicks.forEach(console.log);
            const clicked = state.clicks.find((c) => c === action.payload);
            if (!clicked) {
                state.clicks = [...state.clicks, action.payload];
                fetch(`/${action.payload}`);
            }
        },
    },
});

export const { addClick } = clickSlice.actions;
export default clickSlice.reducer;
