import React from 'react';
import { filePath, GreenLogo } from '../../../utils/media';

/**
 * Icon for the green logo
 */

interface GreenIconProps {
    action?: () => void;
    height: number;
    logo: GreenLogo;
}

export const GreenIcon: React.FC<GreenIconProps> = ({
    action,
    height,
    logo,
}) => {
    return (
        <div
            style={{ height: `${height}em` }}
            onClick={action}
            className="w-fit m-6"
        >
            <img
                src={filePath(logo)}
                draggable="false"
                className="w-full h-full object-contain"
            />
        </div>
    );
};
