import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTexts } from '../../utils/texts';
import { OsallistuBlock } from '../../components/Osallistu/OsallistuBlock';
import { addClick } from '../../reducers/clickReducer';
import { useAppDispatch } from '../../store';

export const Osallistu = () => {
    const texts = useTexts();

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(addClick('click-osallistu'));
    }, []);

    return (
        <div className="">
            <Helmet>
                <title>{texts.nav.vaalit2}</title>
            </Helmet>
            <OsallistuBlock />
        </div>
    );
};
