import React, { useState } from 'react';
import { NavItemProps } from '../NavItem';
import { NavItem } from '../NavItem';
import classNames from 'classnames';

interface NavDropdownProps {
    name: string;
    items: NavItemProps[];
}

export const NavDropdown: React.FC<NavDropdownProps> = ({ name, items }) => {
    const [hover, setHover] = useState(false);

    return (
        <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            className={classNames(
                'h-full w-fit flex flex-col justify-center px-5 relative cursor-pointer hover:bg-s-white/5 transition-colors duration-100'
            )}
        >
            <h3 className="text-center align-middle text-[22px] font-Roboto text-s-white">
                {name}
            </h3>

            {hover && (
                <div className="w-full flex flex-col absolute top-[4.5em] left-0 outline outline-1 outline-s-black/10">
                    {items.map((item) => (
                        <NavItem
                            key={item.name}
                            name={item.name}
                            path={item.path}
                            className="h-min-[4.5em] w-full px-4 py-4 flex flex-col justify-center bg-s-gray hover:bg-s-white transition-colors duration-100"
                            innerClassName="text-left align-middle text-[22px] font-Roboto text-s-dblue"
                        />
                    ))}
                </div>
            )}
        </div>
    );
};
