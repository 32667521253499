import React from 'react';
import { SanteriIcon } from '../../Icons';
import { Link, useLocation } from 'react-router-dom';
import { NavList } from './NavList';
import { NavDrawer } from './NavDrawer';
import { LangMenu } from './LangMenu';

export const NavigationBar = () => {
    const loc = useLocation();

    return (
        <div className="w-full h-[4.5em] z-30 sticky top-0 left-0 bg-s-dgreen flex flex-row select-none">
            <Link
                to={{ pathname: '/', search: loc.search }}
                draggable="false"
                className="hover:bg-s-white/5 active:bg-s-white/10 transition-colors duration-100"
            >
                <SanteriIcon height={4.5} logo="slogo_white.svg" />
            </Link>
            <NavList />
            <NavDrawer />
            <LangMenu />
        </div>
    );
};
