import { Link } from 'react-router-dom';
import { useTexts } from '../../../utils/texts';

export const CV = () => {
    const texts = useTexts();

    return (
        <div className="w-full flex flex-row justify-center bg-s-gray pt-3 pb-16">
            <div className="bg-s-dblue xl:px-24 max-xl:px-12 py-8 xl:min-w-[60%] h-fit w-fit xl:rounded-3xl max-xl:w-full text-s-white">
                <h2 className="font-Roboto text-center text-4xl max-sm:text-3xl text-s-bblue pb-8">
                    {texts.santeri.cv.title}
                </h2>
                <p className="font-Roboto py-2 text-xl">
                    <strong className="pr-3">•</strong>
                    {texts.santeri.cv.i1}
                </p>
                <p className="font-Roboto py-2 text-xl">
                    <strong className="pr-3">•</strong>
                    {texts.santeri.cv.i2}
                </p>
                <p className="font-Roboto py-2 text-xl">
                    <strong className="pr-3">•</strong>
                    {texts.santeri.cv.i3}
                </p>
                <p className="font-Roboto py-2 text-xl">
                    <strong className="pr-3">•</strong>
                    {texts.santeri.cv.i4}
                </p>

                <p className="font-Roboto py-2 text-xl">
                    <strong className="pr-3">•</strong>
                    <i>
                        <Link
                            className="hover:text-s-green font-Robotoserif text-s-bblue text-2xl"
                            to="https://ajatuspajavisio.fi/julkaisut/venaja-vihrein-silmin/"
                        >
                            {texts.santeri.cv.i5}
                        </Link>
                    </i>
                </p>

                <p className="font-Roboto py-2 text-xl">
                    <strong className="pr-3">•</strong>
                    <i>
                        <Link
                            className="hover:text-s-green font-Robotoserif text-s-bblue text-2xl"
                            to="https://kielilahettilaat.fi"
                        >
                            {texts.santeri.cv.i6}
                        </Link>
                    </i>
                </p>
                <p className="font-Roboto py-2 text-xl">
                    <strong className="pr-3">•</strong>
                    {texts.santeri.cv.i7}
                </p>
                <p className="font-Roboto py-2 text-xl">
                    <strong className="pr-3">•</strong>
                    {texts.santeri.cv.i8}
                </p>
            </div>
        </div>
    );
};
