import { useTexts } from '../../../../utils/texts';
import { BannerActionBar } from './BannerActionBar';
import { BannerText } from './BannerText';
import { SmallActionBar } from './SmallActionBar';

/**
 * Fullscreen banner overlay with text
 */

export const BannerOverlay = () => {
    const texts = useTexts();

    return (
        <div className="w-full h-full absolute top-0 left-0 flex flex-col">
            <div className="w-full h-full flex flex-col items-left justify-between max-xl:hidden">
                <div className="w-full max-2xl:hidden">
                    <BannerText
                        fontSize={96}
                        text={texts.motto1}
                        className="w-full px-12 mt-[8rem] font-Robotoserif font-bold"
                    />
                    <BannerText
                        fontSize={60}
                        text={texts.motto2}
                        className="w-full px-12 mt-8 font-Roboto font-light"
                    />
                    <BannerText
                        fontSize={300}
                        text="215"
                        className="w-full px-40 -mt-20 font-Robotoserif font-bold text-[19rem] text-s-black"
                    />
                </div>
                <div className="w-full 2xl:hidden">
                    <BannerText
                        fontSize={72}
                        text={texts.motto1}
                        className="w-full px-12 mt-[5rem] font-Robotoserif font-bold"
                    />
                    <BannerText
                        fontSize={45}
                        text={texts.motto2}
                        className="w-full px-12 mt-8 font-Roboto font-light"
                    />
                    <BannerText
                        fontSize={225}
                        text="215"
                        className="w-full px-40 -mt-12 -mb-10 font-Robotoserif font-bold text-[19rem] text-s-black"
                    />
                </div>
                <BannerActionBar />
            </div>
            <div className="w-full h-full flex flex-col justify-end xl:hidden">
                <div className="p-6 mb-12 bg-s-bgreen sm:hidden">
                    <h1 className="font-Robotoserif font-bold text-2xl text-s-dgreen">
                        {texts.motto1}
                    </h1>
                    <h3 className="font-Roboto font-light text-xl mt-4 text-s-black">
                        {texts.motto2}
                    </h3>
                    <h1 className="font-Robotoserif font-bold -mb-6 -mt-6 text-[25vw] text-s-dgreen">
                        215
                    </h1>
                </div>
                <div className="p-6 mb-24 bg-s-bgreen max-sm:hidden flex flex-row items-center">
                    <div className="w-full">
                        <h1 className="font-Robotoserif font-bold text-2xl text-s-dgreen">
                            {texts.motto1}
                        </h1>
                        <h3 className="font-Roboto font-light text-xl mt-4 text-s-black">
                            {texts.motto2}
                        </h3>
                    </div>
                    <div className="w-full flex flex-row justify-center">
                        <h1 className="font-Robotoserif font-bold text-9xl text-s-dgreen">
                            215
                        </h1>
                    </div>
                </div>

                <SmallActionBar />
            </div>
        </div>
    );
};

/*

*/
