import React from 'react';
import { Link } from 'react-router-dom';
import { SocialMedia, SocialIcons, SocialUrls } from '../../../utils/media';

/**
 * Social media icon
 */

interface SocialIconProps {
    social: SocialMedia;
    height: number;
}

export const SocialIcon: React.FC<SocialIconProps> = ({ social, height }) => {
    const h = `${height}em`;

    return (
        <Link
            to={SocialUrls[social]}
            target="_blank"
            draggable="false"
            style={{ height: h }}
            className="w-fit m-6 max-sm:m-4"
        >
            <img
                src={SocialIcons[social]}
                draggable="false"
                className="h-full w-full object-contain"
            />
        </Link>
    );
};
