import { useState } from 'react';
import { BlogListing, Article } from '../../BlogListing';
import { useTexts } from '../../../utils/texts';

export const BlogPicks = () => {
    const texts = useTexts();
    const [blogList, setBlogList] = useState<Article[]>([]);

    if (blogList.length < 1) {
        fetch('/blog-list')
            .then((res) => {
                return res.text();
            })
            .then((html) => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(html, 'text/html');
                const tgs = doc.getElementById('tags')?.children;
                const ch = doc.getElementById('articles')?.children;
                if (ch && tgs) {
                    const c = ch as HTMLCollection;
                    const t = tgs as HTMLCollection;
                    const tags: { id: number; name: string }[] = [];

                    for (let i = 0; i < t.length; i++) {
                        tags.push({
                            id: Number(t[i].getAttribute('tagid')),
                            name: t[i].innerHTML,
                        });
                    }

                    const articles: Article[] = [];
                    for (let i = 0; i < c.length && i < 3; i++) {
                        const el = c[i];
                        const tagList =
                            el.getAttribute('tags')?.split(',') ?? [];
                        articles.push({
                            id: Number(el.getAttribute('articleid')),
                            title: el.children[0].innerHTML,
                            desc: el.children[1].innerHTML,
                            created: el.children[2].innerHTML,
                            tags: tagList
                                .map((s) => Number(s))
                                .map((n) => {
                                    return {
                                        id: n,
                                        name: tags.find((o) => o.id === n)
                                            ?.name as string,
                                    };
                                }),
                        });
                    }
                    setBlogList(articles);
                }
            })
            .catch((err) => {
                console.log('Failed to fetch articles: ', err);
            });
    }

    return (
        <div className="w-full min-h-[calc(100vh-4.5em)] flex flex-row justify-around bg-s-gray">
            <div className="w-full flex flex-col justify-center">
                <div className="px-8 h-full flex flex-col items-center justify-start">
                    <h1 className="font-Robotoserif py-20 text-center font-bold max-sm:text-4xl max-xl:text-5xl text-6xl text-s-dgreen">
                        {texts.home.blogititle}
                    </h1>
                    {blogList.map((article) => (
                        <BlogListing key={article.id} {...article} />
                    ))}
                </div>
            </div>
        </div>
    );
};
