import { Helmet } from 'react-helmet';
import {
    KukaSanteri,
    Vaaliteemani,
    BlogPicks,
    MediaEmbed,
    BannerImage,
    BannerOverlay,
} from '../../components/Homepage';
import { useTexts } from '../../utils/texts';
import { useAppDispatch } from '../../store';
import { useEffect } from 'react';
import { addClick } from '../../reducers/clickReducer';

export const Home = () => {
    const texts = useTexts();

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(addClick('click-home'));
    }, []);

    return (
        <div>
            <Helmet>
                <title>{texts.home.title}</title>
            </Helmet>
            <BannerImage img="wide_outdoors.png">
                <BannerOverlay />
            </BannerImage>
            <Vaaliteemani />
            <KukaSanteri />
            <div className="w-full flex flex-row pt-8 justify-around max-xl:flex-wrap bg-s-gray">
                <BlogPicks />
                <MediaEmbed />
            </div>
            <div className="w-full bg-s-gray md:hidden">
                <BannerImage img="square_sitting_rock.png" />
            </div>
        </div>
    );
};
