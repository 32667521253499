import { Link } from 'react-router-dom';
import { filePath } from '../../../utils/media';
import { useTexts } from '../../../utils/texts';

export const SanteriInfo = () => {
    const texts = useTexts();

    return (
        <div className="w-full min-h-[calc(100vh-4.5em)] flex flex-row justify-around bg-s-gray">
            <div className="w-full flex flex-col justify-start lg:px-36">
                <div className="w-full flex flex-row justify-center 2xl:hidden">
                    <div className="w-full h-[calc(100vh-17em)] min-h-[26em] relative">
                        <img
                            src={filePath('square_leaning_train.png')}
                            className="h-full w-full object-cover select-none rounded-3xl"
                            style={{ objectPosition: '0% 30%' }}
                            draggable="false"
                        />
                    </div>
                </div>
                <div className="mt-20 max-lg:px-8">
                    <h3 className="font-Roboto text-2xl text-s-black font-medium">
                        {texts.santeri.part1.p1.title}{' '}
                    </h3>
                    <p className="font-Roboto pt-2 leading-normal font-light text-2xl text-s-black">
                        {texts.santeri.part1.p1.text}
                    </p>
                </div>
                <div className="my-16 max-lg:px-8">
                    <h3 className="font-Roboto text-2xl text-s-black font-medium">
                        {texts.santeri.part1.p2.title}{' '}
                    </h3>
                    <p className="font-Roboto pt-2 leading-normal font-light text-2xl text-s-black">
                        {texts.santeri.part1.p2.text}
                    </p>
                </div>

                <div className="mb-16 max-lg:px-8">
                    <h3 className="font-Roboto text-2xl text-s-black font-medium">
                        {texts.santeri.part1.p3.title}{' '}
                    </h3>
                    <p className="font-Roboto pt-2 leading-normal font-light text-2xl text-s-black">
                        {texts.santeri.part1.p3.text}
                    </p>
                </div>

                <div className="mb-16 max-lg:px-8">
                    <h3 className="font-Roboto text-2xl text-s-black font-medium">
                        {texts.santeri.part1.p4.title}{' '}
                    </h3>
                    <p className="font-Roboto pt-2 leading-normal font-light text-2xl text-s-black">
                        {texts.santeri.part1.p4.text1}
                        <i>
                            <Link
                                className="hover:text-s-green font-Robotoserif text-s-dblue text-2xl"
                                to="/artikkelit?artikkeli=10"
                            >
                                {texts.santeri.part1.p4.link}
                            </Link>
                        </i>
                        {texts.santeri.part1.p4.text2}
                    </p>
                </div>
            </div>
            <img
                src={filePath('square_leaning_train.png')}
                className="h-[calc(100vh-12em)] rounded-3xl min-h-[40em] w-auto max-2xl:hidden"
            />
        </div>
    );
};
