import { filePath } from '../../../utils/media';
import { useTexts } from '../../../utils/texts';

export const Lapsuus = () => {
    const texts = useTexts();

    return (
        <div className="w-full bg-s-gray pt-4">
            <div className="flex flex-row justify-evenly">
                <div className="flex flex-col items-center justify-start">
                    <img
                        src={filePath('square_childhood.png')}
                        className="rounded-3xl max-h-[max(60vh,24em)]"
                    />
                    <i>
                        <h3 className="p-4 font-Robotoserif text-s-black text-2xl max-sm:text-xl">
                            {texts.santeri.childhood}
                        </h3>
                    </i>
                </div>
                <div />
            </div>
        </div>
    );
};
