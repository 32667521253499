import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
    const { pathname, hash } = useLocation();

    useEffect(() => {
        if (hash.length === 0) window.scrollTo(0, 0);
        else document.querySelector(hash)?.scrollIntoView();
    }, [pathname]);

    return null;
};
