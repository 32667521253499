import React, { useEffect } from 'react';
import { FirstBlock } from '../../components/VaaliPage';
import { Helmet } from 'react-helmet';
import { VaaliPoints } from '../../components/VaaliPage/VaaliPoints';
import { useTexts } from '../../utils/texts';
import { TurvaBlock } from '../../components/VaaliPage/VaaliBlocks/TurvaBlock';
import { TalousBlock } from '../../components/VaaliPage/VaaliBlocks/TalousBlock';
import { IlmastoBlock } from '../../components/VaaliPage/VaaliBlocks/IlmastoBlock';
import { useAppDispatch } from '../../store';
import { addClick } from '../../reducers/clickReducer';

export const Vaaliohjelma = () => {
    const texts = useTexts();

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(addClick('click-vaaliteema'));
    }, []);

    return (
        <div className="">
            <Helmet>
                <title>{texts.nav.vaalit1}</title>
            </Helmet>

            <div className="w-full bg-s-gray py-16 px-8 flex flex-col items-center">
                <h1 className="w-full font-Robotoserif font-bold text-6xl max-md:text-5xl text-s-dgreen text-center">
                    {texts.motto1}
                </h1>
                <h2 className="w-full pt-4 font-Roboto font-light text-4xl max-md:text-2xl max-sm:text-xl text-s-dgreen text-center">
                    {texts.motto2}
                </h2>
                <h1 className="w-full font-Robotoserif font-bold md:text-[12rem] max-md:text-9xl md:-my-14 text-center text-s-dgreen">
                    215
                </h1>
                <h3 className="w-full pt-20 font-Roboto font-medium text-3xl max-md:text-xl text-s-dblue text-center">
                    {texts.vaali.title}
                </h3>
            </div>

            <FirstBlock />
            <VaaliPoints />
            <TurvaBlock />
            <TalousBlock />
            <IlmastoBlock />
        </div>
    );
};
