import { SocialIcon, GreenIcon } from '../../../../Icons';

/**
 * Actions inside a BannerOverlay
 */

export const BannerActionBar = () => {
    return (
        <div className="w-full flex flex-row justify-between px-10 py-10 select-none">
            <div className="flex flex-row justify-between items-end">
                <SocialIcon social="instagram" height={3} />
                <SocialIcon social="facebook" height={3} />
                <SocialIcon social="twitter" height={3} />
                <SocialIcon social="youtube" height={3} />
                <SocialIcon social="tiktok" height={3} />
            </div>
            <GreenIcon height={5} logo="logo_grn_white.svg" />
        </div>
    );
};
