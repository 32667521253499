import React from 'react';
import { filePath, SanteriLogo } from '../../../utils/media';

/**
 * Icon of Santeri
 */

interface SanteriIconProps {
    action?: () => void;
    height: number;
    logo: SanteriLogo;
}

export const SanteriIcon: React.FC<SanteriIconProps> = ({
    action,
    height,
    logo,
}) => {
    const h = `${height}em`;
    const w = `${height * 2.65}em`;
    const i = `${height * 0.5}em`;
    const p = `${height * 0.25}em`;
    const src = filePath(logo);

    return (
        <div style={{ height: h, width: w, padding: p }} onClick={action}>
            <img src={src} height={i} width="auto" draggable="false" />
        </div>
    );
};
