import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export interface NavItemProps {
    name: string;
    path: string;
    className?: string;
    innerClassName?: string;
    noParams?: boolean;
    target?: string;
}

export const NavItem: React.FC<NavItemProps> = ({
    name,
    path,
    className,
    innerClassName,
    noParams,
    target,
}) => {
    const loc = useLocation();

    return (
        <Link
            draggable="false"
            to={{ pathname: path, search: noParams ? '' : loc.search }}
            className={
                className ||
                'h-full w-fit flex flex-col justify-center px-5 hover:bg-s-white/5 active:bg-s-white/10 transition-colors duration-100'
            }
            target={target}
        >
            <h3
                className={
                    innerClassName ||
                    'text-center align-middle text-[22px] font-Roboto text-s-white'
                }
            >
                {name}
            </h3>
        </Link>
    );
};
