import { useAppSelector } from '../store';

const fi = {
    motto1: 'Valitse Santeri',
    motto2: '– koska maailma odottaa tekoja',
    lahjoita: 'Lahjoita',

    osallistu: {
        title: 'Santeri tavattavissa!',
        text: 'Vaalit ovat yhteispeliä muiden ehdokkaiden ja puolueiden kanssa. Toivottavasti saamme tutustua kanssasi vaalikevään tapahtumissa! Halutessasi olet myös lämpimästi tervetullut mukaan tukiryhmääni.',
        events: 'Tulevat tapahtumat',
    },

    vaali: {
        title: 'Vaaliohjelmani eduskuntavaaleissa 2023',
        part1: {
            p1: {
                title: 'Tällä sivulla voit tutustua vaaliohjelmaani',
                text: 'eduskuntavaaleissa 2023. Juuri nyt eduskuntaan tarvitaan kansainvälistä osaamista sekä aidosti yhteistyöhön sitoutuneita edustajia, jotka etsivät kaikki puoluerajat ylittäviä ratkaisuja. Haluan torjua viholliskuvien kasvamista suomalaisessa yhteiskunnassa.',
            },
            p2: {
                title: 'Suomalaisten arvopohja on murroksessa.',
                text: 'Yksilökeskeinen elämäntapa on johtanut yksinäisyyteen ja kestämättömään kulutukseen, ympäristön ja lähimmäistemme kustannuksella. Oman edun tavoittelun sijaan politiikan on aika alkaa kantaa vastuuta muista ihmisistä, ympäristöstä ja omasta lähiyhteisöstämme. Ihmiskunnan vahvuus on yhteistyössä, eikä kukaan meistä pärjää tässä maailmassa kokonaan yksin.',
            },
            p3: {
                title: 'Onko elämässä tärkeintä vapaus, vapaus, vapaus',
                text1: '– vaiko sittenkin alkuperäisen ihanteen mukaisesti vapaus, veljeys ja tasa-arvo?',
                text2: 'Parhaimmillaan yksilön vapaus toteutuu hänen ollessaan aidosti vertainen muiden ihmisten kanssa. Poliittisen filosofiani ytimessä on ajatus ahkerasta yksilöstä, joka saa tukea ei itsekkään edun tavoitteluun, vaan oman itsensä sekä koko lähiyhteisönsä kehittämiseen. Kannamme jokainen yhteistä vastuuta.',
            },
        },
        vaalipoints: {
            title: 'Tutustu tarkemmin vaalikärkiini:',
            i1: 'Ulko- ja turvallisuuspolitiikka',
            i2: 'Talous ja toimeentulo',
            i3: 'Ilmastokriisi ja luonnonsuojelu',
            i4: 'Kielipolitiikka',
            i5: 'Muut aiheet',
        },

        turva: {
            motto1: 'Valitse Santeri',
            motto2: '– koska maailma odottaa tekoja',
            introtext: {
                p1: 'Tuleva eduskunta tekee historiallisia linjauksia Suomen ulkopolitiikasta ja turvallisuudesta. Pian on aika päättää, millainen Nato-jäsenmaa haluamme olla, miten edistämme kestävää rauhaa Euroopassa ja mikä on seuraavien vuosikymmenien Venäjä-politiikan peruslinja.',
                p2: 'Suomi kuuluu Eurooppaan ja Eurooppa on meille tärkeä. Kannatan tiiviimpää eurooppalaista ja pohjoismaista yhteistyötä. Lisäksi meidän on aloitettava asevelvollisuuden vaiheittainen uudistus, śilti puolustuskyvystä tinkimättä. Lähialueen kriisien lisäksi on pidettävä mielessä myös kehittyvien valtioiden tilanne – globaalissa maailmassa kaukaisistakin ongelmista tulee nopeasti yhteisiä.',
            },

            rus: {
                title: 'Mitä tehdä Venäjälle?',
                p1: {
                    b1: 'Lyhyellä aikavälillä ',
                    t1: 'Venäjä-suhteiden parantuminen ei ole mahdollista, ennen kuin Venäjä vetäytyy kokonaan Ukrainasta ja sitoutuu jälleen kansainväliseen sääntöpohjaiseen järjestelmään.',
                },
                p2: {
                    b1: 'Venäjän tavoite on luoda epäjärjestystä ',
                    t1: 'omalla lähialueellaan, koska vain sekasorrossa Venäjä voi yrittää kääntää muutosta omaksi edukseen. Tätä estetään vahvistamalla Euroopan yhtenäisyyttä ja tukemalla Venäjän raja-naapureiden poliittista itsenäisyyttä erityisesti Kaukasuksen ja Keski-Aasian alueilla, jonka maat ovat aivan asemassa Venäjän tulevan kansainvälisen aseman kehityksessä.',
                },
                p3: {
                    t1: 'Venäjä on epävakaa ja Suomen pitää varautua nopeisiinkin poliittisiin, taloudellisiin ja humanitaarisiin kriiseihin naapurimaassa. ',
                },
                p4: {
                    b1: 'Pitkällä aikavälillä ',
                    t1: 'Suomen turvallisuuspolitiikan on lähdettävä siitä, että Venäjä kykenee tulevaisuudessa jälleen keskittämään merkittävää sotilasvoimaa Suomenkin rajoille viimeistään 15–20 vuoden kuluessa. Ulkopolitiikan on toimittava tämän kehityksen estämiseksi.',
                },
                p5: {
                    t1: 'Kehitetään venäjänkielisen Yle Novostin toimintaa. Perustetaan Baltian maiden kanssa yhteinen venäjänkielinen uutistoimisto, joka voisi toimia vastavoimana Venäjän valtiolliselle medialla kotimaassa ja lähialueilla.',
                },
                p6: {
                    t1: 'Suomen valtion tulee kaikessa toiminnassaan sitoutua demokratian ja ihmisoikeuksien periaatteisiin, myös silloin, kun toimitaan Venäjän federaation kansalaisten parissa.',
                },
                p7: {
                    t1: 'Suomen on myös osoitettava tavallisille venäläisille, että sitoutuminen demokratiaan ja sodanvastaisuuteen on vaatimus yhteistyön palauttamiselle. Historialliselle pienen maan passiivisuuden politiikalle ei Suomella ole tulevaisuudessa enää edellytyksiä.',
                },
            },
        },

        ilmasto: {
            motto1: 'Valitse Santeri',
            motto2: '– koska luonto odottaa tekoja',
            introtext: {
                p1: 'Ilmastonmuutos ja luonnon monimuotoisuuden heikkeneminen ovat ihmiskunnan kohtalonkysymyksiä, joiden suunta ratkaistaan tällä vuosikymmenellä. Ympäristöarvot on huomioitava kaikessa päätöksenteossa ja hiilineutraali Suomi saavutettava vuonna 2030.',
                p2: 'Samalla moni meistä suomalaisista elää sellaisessa rikkaudessa, josta ei ennen osattu edes haaveilla. Meidän tehtävämme ei ole tavoitella entistä suurempaa runsautta jälkeläisillemme, vaan suojella tulevia sukupolvia kokonaisvaltaiselta köyhyydeltä. Uudistetaan maa-aineslakia, kaupunkipolitiikkaa ja metsäpolitiikkaa. ',
            },
        },

        talous: {
            motto1: 'Valitse Santeri',
            motto2: '– koska ihmiset odottavat tekoja',
            introtext: {
                p1: 'Suomen valtiontalous on epätasapainossa. Silti näissä vaaleissa ei tarvitse pysyvästi luopua hyvinvointivaltion ihanteista. Hyvinvoiva Suomi rakennettiin sotien jälkeisessä köyhässä Suomessa. On meidän aikamme arvovalinta, haluammeko jatkaa entisten sukupolvien elämäntyötä nyt, kun siirrymme itse maksajien puolelle. ',
                p2: 'Samanaikaisesti eriarvoisuus, yksinäisyys ja köyhyys ovat yhä läsnä maassamme. Eräs Suomen entisistä sosiaaliministereistä totesi hyvin, että isänmaallisuuteen kuuluu kantaa huolta jokaisesta suomalaisesta. Myöskään ikääntyminen ei ole sairaus vaan etuoikeus, jota toivon meistä jokaiselle. Taloustilanteesta tai suhdanteesta riippumatta jokainen ihminen on aina lähimmäinen. ',
                p3: 'Käytännön tekoina meidän on toteutettava perustulo sosiaaliturvan kokonaisuudistamiseksi, siirtää verotuksen painopistettä työn verotuksesta haitalliseen kulutukseen ja lisättävä työvoiman koulutustasoa. ',
            },
        },
    },

    nav: {
        santeri: 'Kuka Santeri?',
        vaalit: 'Eduskuntavaalit',
        vaalit1: 'Vaaliohjelma',
        vaalit2: 'Osallistu',
        kunta: 'Kuntapolitiikka',
        kunta1: 'Tavoitteeni',
        kunta2: 'Valtuustotyö',
        blogi: 'Blogi',
        yhteys: 'Ota yhteyttä!',
    },

    home: {
        title: 'Etusivu',
        teemat: {
            title: 'Vaaliteemani',
            teema1: {
                title: 'Turvallisuuspolitiikka',
                text: 'Ensi vaalikaudella valitsemme Suomen Nato-politiikan suunnan. Muuttuvasta maailmantilanteesta huolimatta voimme kehittää asevelvollisuutta tasa-arvoisempaan suuntaan sekä vahvistaa turvallisuuttamme yhdessä Ruotsin ja Euroopan kanssa. Venäjä-politiikassa on kurssinmuutoksen aika. ',
                linkText: 'Lue lisää ulko- ja turvallisuuspolitiikastani.',
            },
            teema2: {
                title: 'Ilmasto- ja ympäristö',
                text: 'Elämme ilmastokriisin kannalta ratkaisevaa vuosikymmentä. Ilmastonmuutoksen pysäyttämiseen tarvitsemme kansainvälistä yhteistyötä. Samalla meidän on pysäytettävä luontokato ja suojeltava paikallisluontoa. Lopetetaan avohakkuut ja liialliset louhosluvat. ',
                linkText: 'Katso kaikki keinoni ympäristöpolitiikassa.',
            },
            teema3: {
                title: 'Talous',
                text: 'Nämä vaalit eivät ole hyvinvointivaltion alasajovaalit. Vihreä rakennemuutos pitää kaikki mukana ja uskaltaa katsoa myös tulevaan. Vetytalous ja vihreä siirtymä luovat kotimaista työtä. Riittävä eläke ja sosiaaliturva varmistavat tasavertaisen arjen jokaiselle. Yrittäjyyden pitää aina kannattaa. Siksi tarvitsemme perustulon nyt. ',
                linkText: 'Näin pelastamme julkisen talouden.',
            },
            teema4: {
                title: 'Yhteistyö',
                text: 'Eduskunnassa haluan näyttää, että politiikkaa voi 2020-luvullakin tehdä yhteistyöllä ja nostamalla muita ylöspäin. Jokainen ihminen on aina lähimmäinen ja jokaisen äänestyksen jälkeen on voitava juoda yhdessä kahvit. Ovatko arvojamme vapaus, vapaus, vapaus – ',
                linkText: 'vaiko vapaus, veljeys ja tasa-arvo?',
            },
        },

        kuka: {
            title: 'Kuka on Santeri Leinonen?',
            p1: 'Olen vihreä poliitikko kolmannessa sukupolvessa ja ehdolla eduskuntaan Uudenmaan vaalipiirissä. ',
            p2: 'Olen opiskellut venäjän kieltä, maailmanpolitiikkaa, islamilaista teologiaa sekä sotatiedettä Helsingissä. ',
            p3: 'Politiikassa toimin kaupunginvaltuutettuna sekä Vihreiden ulko- ja turvallisuuspoliittisen työryhmän puheenjohtajana. Minun arvojani ovat ahkeruus, uteliaisuus ja lähimmäisenrakkaus. ',
            link: 'Tutustu Santeriin.',
            uutis: {
                title: 'Tilaa Santerin uutiskirje',
                text: 'Saat kampanjakevään uusimmat kuulumiset suoraan sähköpostiisi.',
                link: 'Tilaa uutiskirje',
            },
        },
        blogititle: 'Tutustu kirjoituksiini',
        mediatitle: 'Santeri sosiaalisessa mediassa',
    },

    santeri: {
        title: 'Kuka on Santeri?',
        part1: {
            p1: {
                title: 'Olen Santeri Leinonen, hyvinkääläinen vihreä kaupunginvaltuutettu ja ehdolla eduskuntaan Uudenmaan vaalipiiristä. ',
                text: 'Lähdin mukaan puolueeseen, koska halusin antaa omaa aikaani ja osaamistani yhteisten ongelmien ratkaisemiseen. Minulle politiikka on keino vaikuttaa oman alueeni ja yhteisöni ihmisten asioihin, ei itsetarkoitus. ',
            },
            p2: {
                title: 'Olen vihreä poliitikko kolmannessa sukupolvessa. ',
                text: 'Isoisäni Heino Leinonen oli Hyvinkään vihreiden ensimmäinen puheenjohtaja. Vihreän liikkeen lisäksi olen myös partioliikkeen kasvatti. Luonto, retkeily sekä yksilön vastuu omasta lähiympäristöstään ovat minulle tärkeitä asioita. Historian tunteminen ja vakaat arvot antavat hyvän pohjan, jolta ponnistaa rakentamaan kaikkien yhteistä tulevaisuutta. ',
            },
            p3: {
                title: 'Ensimmäinen työpaikkani oli Folkhälsanin ruotsinkielisessä päiväkodissa Hyvinkäällä. ',
                text: 'Myöhemmin pääsin osalliseksi elävästä kaksikielisyydestä niin opinnoissa kuin työelämässä, jossa olen työskennellyt pääosin ruotsin- tai kaksikielisillä työpaikoilla. Kansalliskielten lisäksi puolustan politiikassa myös monipuolista kieltenopiskelua. Olen aktiivinen kielilähettiläs. ',
            },
            p4: {
                title: 'Kuntapolitiikassa olen tehokas mutta yhteistyökykyinen valtuutettu, ',
                text1: 'joka etsii yhteisiä vaihtoehtoja. Olen tarkastuslautakunnan jäsen. Aloitteestani vihreä ryhmä esitti ',
                link: 'mielenterveyden ensiapukoulutuksen',
                text2: ' järjestämistä kaikille Hyvinkään koululaisille. Tämä hyväksyttiin yksimielisesti vuonna 2022.',
            },
        },
        childhood: 'Serkukset Yrjö-vaarin kanssa kottikärryilemässä v. 2006',
        arvot: {
            title: 'Minun arvoni',
            i1: {
                title: 'Ahkeruus – ',
                text: 'ideoiden kokeilemiseen pitää antaa tukea ja yrittämisen aina kannattaa.',
            },
            i2: {
                title: 'Uteliaisuus – ',
                text: 'suhtaudun avoimesti uuteen, puolustan oppimista, sivistystä ja koulutusta.',
            },
            i3: {
                title: 'Yhteistyö – ',
                text: 'rakennan kompromisseja ja etsin yhteisiä näkemyksiä yli puoluerajojen.',
            },
            i4: {
                title: 'Luonnonsuojelu – ',
                text: 'luonto ja ilmasto muodostavat pohjan kaikelle ihmisen toiminnalle.',
            },
            i5: {
                title: 'Lähimmäisenrakkaus – ',
                text: 'jokainen ihminen on aina lähimmäinen, tilanteesta ja suhdanteesta riippumatta.',
            },
        },

        cv: {
            title: 'Ansioluettelo – CV',
            i1: 'Kaupunginvaltuutettu',
            i2: 'Tarkastuslautakunnan jäsen',
            i3: 'Kirkkovaltuutettu sekä kirkkoneuvoston jäsen',
            i4: 'Vihreiden ulko- ja turvallisuuspoliittisen työryhmän puheenjohtaja',
            i5: 'Venäjä vihrein silmin -kirjan toimittaja',
            i6: 'Kielilähettiläs',
            i7: 'Suomen Partiolaisten yhteiskuntasuhde-valiokunnan jäsen',
            i8: 'Varusmiespalvelus 2020 – 2021',
        },

        part2: {
            p1: {
                title: 'Opiskelen venäjän kieltä, maailmanpolitiikkaa ',
                text: 'ja islamilaista teologiaa Helsingin yliopistossa sekä sotatiedettä Maanpuolustuskorkeakoululla. Valmistun kandidaatiksi keväällä 2023. Opintojeni kautta olen erikoistunut erityisesti venäläiseen kulttuuriin sekä Venäjän ulko- ja turvallisuuspolitiikkaan.',
            },
            p2: {
                title: 'Toimin vihreiden ulko- ja turvallisuuspoliittisen työryhmän puheenjohtajana, ',
                text: 'mitä kautta olen saanut seuralta läheltä viime aikojen ajankohtaisia tilanteita. Olimme mukana valmistelemassa Vihreän puolueen Nato-kantaa sekä vihreän ulkopolitiikan virallisia linjoja. Työryhmässä olemme saaneet perehtyä niin kehitysyhteistyöhön, kokonaisturvallisuuteen kuin Venäjän sotaan Ukrainassakin.',
            },
            p3: {
                text: 'Vapaa-ajalla toimin vapaaehtoisena monissa järjestöissä sekä harrastan teatteria. Lisäksi soitan haitaria ja viihdyn katusoittajana. Harmonikan kanssa olen saanut kiertää ympäri Suomen ja kohdata ihmisten ilot sekä surut. Kaupungin arkeen hetkeksi pysähtyvä katusoittaja näkeekin usein elämästä sellaisia puolia, joista arjessa kävelemme helposti ohi.',
            },
        },
    },
};

const sv = {
    motto1: 'Välj Santeri',
    motto2: '– eftersom världen väntar gärningar',
    lahjoita: 'Donera',

    osallistu: {
        title: 'Kom och träffa Santeri!',
        text: 'Nationella val är ett gemensamt projekt för oss alla, såväl kandidater some partier. Jag hoppas att jag får lärä känna dig på vårens valevenemang! Är du intresserad, så är du också varmt välkommen att komma in i min stödgrupp!',
        events: 'Evenemang',
    },

    vaali: {
        title: 'Mina målsättningar i riksdagsvalet 2023',
        part1: {
            p1: {
                title: 'På denna sida kan du bekanta dig med mitt valprogram ',
                text: 'i riksdagsvalet 2023. Just nu behöver vi utrikespolitiska kunskaper i riksdagen och sådana ledamöter som är genuint intresserade av att samarbeta och söka lösningar över alla partigränser. Jag vill förhindra tillväxten av fiendebilder i det finländska samhället.',
            },
            p2: {
                title: 'Finländarnas värdegrund står under förändring.',
                text: 'En individorienterad livsstil har lett till ensamhet och ohållbar konsumtion i vårt samhälle, på bekostnad av miljön och medmänniskor. Istället för att driva ens egna intressen, är det dags för politiken att börja ta ansvar för andra människor, miljön och vårt eget lokalsamhälle. Mänsklighetens styrka ligger i samarbete, och ingen av oss kan leva i denna värld helt ensam.',
            },
            p3: {
                title: 'Vad är det viktigaste i livet? Frihet, frihet och frihet',
                text1: '– eller, i enlighet med det ursprungliga idealet, frihet, jämlikhet och broderskap? ',
                text2: 'En individs frihet förverkligas bäst när hen på riktigt är jämställd med andra människor. Kärnan i min politiska filosofi är idén om en flitig individ som strävar inte efter själviska intressen, utan efter att utveckla sig själv och hela sin lokala gemenskap. Vi har alla ett gemensamt ansvar.',
            },
        },
        vaalipoints: {
            title: 'Läs mera om mina valteman:',
            i1: 'Utrikes- och säkerhetspolitik',
            i2: 'Ekonomi och uppehälle',
            i3: 'Klimatkrisen och naturvård',
            i4: 'Språkpolitik',
            i5: 'Andra åsikter',
        },

        turva: {
            motto1: 'Välj Santeri',
            motto2: '– eftersom världen väntar gärningar',
            introtext: {
                p1: 'Den kommande riksdagen kommer att göra historiska politiska val för Finlands utrikespolitik och säkerhet. Snart är det dags att bestämma, hurdan NATO-medlem vi vill bli, hur vi ska främja hållbar fred i Europa och vad som kommer att vara de viktigaste grundlinjerna för Finlands Rysslandspolitik under de kommande decennierna.',
                p2: 'Finland tillhör till Europa och Europa är viktigt för oss. Jag stöder ett tätare europeiskt och nordiskt samarbete. Dessutom bör vi påbörja en stegvis reform av värnplikten, utan att kompromissa med försvarsförmågan. Utöver kriserna i närområdet måste också utvecklingsländernas situation tas i beaktandet – i den globala världen blir även avlägsna problem snabbt gemensamma.',
            },

            rus: {
                title: 'Vad ska vi göra med Ryssland?',
                p1: {
                    b1: 'På kort sikt ',
                    t1: 'är förbättringen av Finlands relationer med Ryssland inte möjligt innan Ryssland helt drar sig ut från Ukraina och återkommer till det internationella regelbaserade systemet.',
                },
                p2: {
                    b1: 'Rysslands mål är att skapa oordning',
                    t1: 'i sitt eget närområde, för endast med kaos kan Ryssland utnyttja världspolitiska förändringar till sin egen fördel. Detta kan förhindras genom att stärka Europas enhet och stödja det politiska oberoendet för Rysslands grannländer, särskilt i Kaukasus och Centralasien. Dessa länder har en viktig position i utvecklingen av Rysslands framtida internationella position.',
                },
                p3: {
                    t1: 'Ryssland är instabilt och Finland bör förbereda sig för snabba politiska, ekonomiska och humanitära kriser i grannlandet.',
                },
                p4: {
                    b1: 'På lång sikt ',
                    t1: 'måste Finlands säkerhetspolitik bygga på att Ryssland igen i framtiden kan kunna koncentrera betydande militärmakt vid Finlands gränser. Detta kan hända redan inom 15–20 år. Utrikespolitiken måste agera för att förhindra denna utveckling.',
                },
                p5: {
                    t1: 'Vi ska utveckla det ryskspråkiga Yle Novostis verksamhet. Vi ska etablera en gemensam ryskspråkig nyhetsbyrå med de baltiska länderna, som kunde fungera som en motkraft till de ryska statliga medierna i Ryssland och de närliggande regionerna.',
                },
                p6: {
                    t1: 'I all sin verksamhet måste den finska staten förbinda sig till demokratins och mänskliga rättigheternas principer, även när det handlar om medborgare i Ryska federationen.',
                },
                p7: {
                    t1: 'Finland måste också visa vanliga ryssar att engagemang för demokrati och antikrig är ett grundläggande krav för att återupprätta samarbetet. I framtiden har Finland inte längre någon förutsättning för den passivitetspolitik som vårt litet land har upprätthållit i historien.',
                },
            },
        },

        ilmasto: {
            motto1: 'Välj Santeri',
            motto2: '– eftersom naturen väntar gärningar',
            introtext: {
                p1: 'Klimatförändringen och utarmningen av naturens mångfald är ödesfrågor för hela mänskligheten. Deras riktning kommer att bestämmas under detta decennium. Naturvärderingar måste tas i beaktandet i varje beslut och ett koldioxidneutralt Finland ska uppnås 2030.',
                p2: 'Samtidigt lever många av oss finländare i en sådan rikedom som tidigare inte ens kungar kunde drömma om. Därför är det inte längre vår uppgift att sträva efter ännu större överflöd för våra arvtagare, utan att skydda de framtida generationerna från en total fattigdom. Marktäktslaget, stadspolitiken och skogspolitiken bör reformeras.',
            },
        },

        talous: {
            motto1: 'Välj Santeri',
            motto2: '– eftersom människor väntar gärningar',
            introtext: {
                p1: 'Finlands offentliga ekonomi är i obalans. Ändå behöver vi inter permanent avstå från välfärdsstatens ideal i detta val. Välfärdsstaten byggdes i ett fattigt Finland efter andra världskriget. Frågan handlar om våra värderingar: vill vi fortsätta detta arbete nu när det är vår tur att bära ansvaret? ',
                p2: 'Samtidigt finns det fortfarande ojämlikhet, ensamhet och fattigdom i vårt land. En av Finlands tidigare socialministrar sade väl att patriotismen betyder att vi tar hand om varje finländare. Att få åldras är ingen sjukdom utan ett privilegium som jag önskar oss alla. Oavsett av konjunkturer eller ekonomin är varje människa alltid vår medmänniska.',
                p3: 'Som några exempel av praktiska åtgärder måste vi omfattande reformera hela sociala trygghetssystemet via basinkomst, flytta beskattningens fokus från skatt på arbete till skadlig konsumtion samt öka arbetskraftens utbildningsnivå.',
            },
        },
    },

    nav: {
        santeri: 'Vem är Santeri?',
        vaalit: 'Riksdagsvalet',
        vaalit1: 'Mitt valprogram',
        vaalit2: 'Kom med',
        kunta: 'Kommunalpolitiken',
        kunta1: 'Mina målsättningar',
        kunta2: 'Mitt arbete',
        blogi: 'Blogg',
        yhteys: 'Tag kontakt!',
    },

    home: {
        title: 'Hemsida',
        teemat: {
            title: 'Mina valteman',
            teema1: {
                title: 'Säkerhetspolitik',
                text: 'Den kommande riksdagen ska välja riktningen för Finlands Nato-politik. Trots den förändrade världssituationen kan vi ändå utveckla värnpliktssystemet och stärka vår säkerhet tillsammans med Sverige och Europa. Det är dags för en kursändring i Rysslandspolitiken. ',
                linkText: 'Läs mera om min utrikes- och säkerhetspolitik.',
            },
            teema2: {
                title: 'Klimat och naturvård',
                text: 'Vi lever i ett avgörande decennium för att stoppa klimatkrisen. För att hindra klimatförändringens påverkan behöver vi internationellt samarbete. Samtidigt bör vi stoppa förlusten av biologisk mångfald och skydda den lokala naturen. Vi ska sluta med kalhygge och alltför stora brytningstillstånd i Nyland. ',
                linkText: 'Kolla här alla mina lösningar inom miljöpolitiken.',
            },
            teema3: {
                title: 'Ekonomin',
                text: 'I detta riksdagsval ska vi inte köra ner vårt välfärdssamhälle för evighet. Den gröna strukturomvandlingen håller alla med och visar vägen till framtiden. Väte-ekonomin och den gröna övergången skapar inhemskt arbete i Finland. Tillräckligt med social trygghet och pension säkerställer en likställd vardag för alla. Företagsamheten ska alltid vara lönsam. Därför behöver vi en allmän basinkomst nu. ',
                linkText: 'Så här ska vi rädda den offentliga ekonomin.',
            },
            teema4: {
                title: 'Samarbete',
                text: 'I riksdagen vill jag visa att vi även på 2020-talet kan göra politik genom att samarbeta och lyfta upp andra. Varje person är alltid vår medmänniska och efter varje omröstning måste vi kunna dricka en kopp kaffe tillsammans. Är våra värderingar frihet, frihet, frihet - eller ',
                linkText: 'frihet, jämlikhet och broderskap?',
            },
        },

        kuka: {
            title: 'Vem är Santeri Leinonen?',
            p1: 'Jag är en grön politiker av tredje generationen  och riksdagsvalskandidat i Nylands valkrets. ',
            p2: 'Jag har studerat ryska språket, världspolitik, islamisk teologi och krigsvetenskap i Helsingfors. Min kandidatavhandling behandlar nationalism i Ryssland. ',
            p3: 'Inom politiken har jag verkat som kommunfullmäktigeledamot samt ordförande för den utrikes- och säkerhetspolitiska arbetsgruppen i De Gröna. Mina värderingar är flit, nyfikenhet och kärlek till våra medmänniskor. ',
            link: 'Bekanta dig med Santeri.',
            uutis: {
                title: 'Prenumerera på Santeris nyhetsbrev',
                text: 'Så får du kampanjvårens senaste nyheter (på finska) direkt till din e-post.',
                link: 'Prenumerera på nyhetsbrev',
            },
        },
        blogititle: 'Bekanta dig med mina inlägg och läs mera!',
        mediatitle: 'Santeri i sociala medier',
    },

    santeri: {
        title: 'Vem är Santeri?',
        part1: {
            p1: {
                title: 'Jag är Santeri Leinonen, hyvingebo, grön kommunfullmäktigeledamot och kandiderar i riksdagsvalet i Nylands valkrets. ',
                text: 'Jag engagerade mig i partiet De Gröna för att jag ville ge min tid och erfarenhet till att lösa vardagliga problem i min egen gemenskap. För mig är politiken ett sätt till att påverka mitt hemområde och dess invånares ärenden, inte något värde i sig.',
            },
            p2: {
                title: 'Jag är grön politiker av tredje generationen. ',
                text: 'Min farfar Heino Leinonen var den första ordföranden för De Gröna i Hyvinge. Förutom den gröna rörelsen har jag också växt upp i scoutrörelsen. Naturen, friluftslivet och individuellt ansvar för sin egen närmiljö är viktiga saker för mig. Bra kunnande av sin egen historia och starka värderingar ger en bra grund för att börja bygga en bättre gemensam framtid för alla.',
            },
            p3: {
                title: 'Min första arbetsplats var på Folkhälsans svenskspråkiga daghem i Hyvinge. ',
                text: 'Senare har jag fått uppleva Finlands levande tvåspråkighet både under mina studier och i arbetslivet. Förutom våra nationalspråk har jag i politiken försvarat också språkinlärningen överhuvudtaget. Jag är en aktiv språkambassadör.',
            },
            p4: {
                title: 'I kommunalpolitiken är jag en effektiv men samarbetsvillig ',
                text1: 'ledamot som söker gemensamma lösningar. Jag är medlem i revisionsnämnden. På mitt initiativ presenterade den gröna kommunfullmäktigegruppen motionen om ',
                link: 'kurser i psykiska första hjälpen',
                text2: ' för alla skolelever i Hyvinge. Detta godkändes enhälligt 2022.',
            },
        },
        childhood: '',
        arvot: {
            title: 'Mina värderingar',
            i1: {
                title: 'Flit – ',
                text: 'man måste stödja nya idéer och företagsamheten ska alltid vara lönsam.',
            },
            i2: {
                title: 'Nyfikenhet – ',
                text: 'Jag är öppen till att lära mig nytt, jag försvarar kultur och utbildning i politiken.',
            },
            i3: {
                title: 'Samarbete – ',
                text: 'Jag bygger broar och söker gemensamma åsikter över partigränserna.',
            },
            i4: {
                title: 'Naturvård – ',
                text: 'naturen och klimatet är grundläggande för all mänsklig verksamhet.',
            },
            i5: {
                title: 'Kärleken till våra medmänniskor – ',
                text: 'varje människa är alltid vår medmänniska, oavsett av den samhälleliga situationen och konjunkturer.',
            },
        },

        cv: {
            title: 'Meritförteckning',
            i1: 'Kommunfullmäktigeledamot',
            i2: 'Medlem i revisionsnämnden',
            i3: 'Kyrkofullmäktige och medlem Hyvinge församlingens kyrkoråd',
            i4: 'Ordförande för den utrikes- och säkehetpolitiska arbetsgruppen i De Gröna',
            i5: 'Chefredaktör i boken "Venäjä vihrein silmin"',
            i6: 'Språkambassadör',
            i7: 'Medlem i Finlands Scouters utskott för samhälleliga relationer',
            i8: 'Beväringstjänst  2020—2021',
        },

        part2: {
            p1: {
                title: 'Jag studerar ryska språket, världspolitik ',
                text: 'och islamisk teologi vid Helsingfors universitet och krigsvetenskap på Försvarshögskolan. Jag ska bli färdig med kandidatexamen i humanistiska vetenskaper våren 2023. Genom mina studier har jag specialiserat mig särskilt på rysk kultur samt Rysslands utrikes- och säkerhetspolitik.',
            },
            p2: {
                title: 'Jag verkar som ordförande för den utrikes- och säkerhetspolitiska arbetsgruppen ',
                text: 'i De Gröna. Genom detta har jag kunnat bekanta mig på nära håll med den aktuella världsituationen. Jag var med på att skriva bakgrundsinformation för De Grönas inre Nato-process och har också börjat förbereda det nya utrikespolitiska programmet för De Gröna. I arbetsgruppen har jag ordnat seminarier om såväl utvecklingssamarbete, övergripande säkerhet som det ryska kriget i Ukraina.',
            },
            p3: {
                text: 'På min fritid arbetar jag frivilligt i många organisationer och gillar också teater. Jag spelar dragspel och spelar gärna på våra gator. Med dragspelet har jag fått resa runt i Finland och uppleva människors glädje och sorg. En gatumusiker som stannar en stund i stadens vardag får ofta se sådana aspekter av livet som lätt går förbi i den upptagna vardagen.',
            },
        },
    },
};

export const useTexts = () => {
    const lang = useAppSelector((state) => state.lang.lang);
    return lang === 'sv' ? sv : fi;
};
