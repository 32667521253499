import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Fullscreen banner image the size of one block
 */

interface InfoBoxProps {
    icon?: JSX.Element;
    title: string;
    text: string;
    linkText: string;
    linkPath: string;
    ml: string;
    mr: string;
}

export const InfoBox: React.FC<InfoBoxProps> = ({
    icon,
    text,
    title,
    linkText,
    linkPath,
    ml,
    mr,
}) => {
    return (
        <div
            className="max-lg:w-full lg:w-3/4 xl:w-[500px] 2xl:w-[460px] 2xl:h-full bg-s-dblue rounded-[3.5rem] max-sm:px-8 max-2xl:px-16 2xl:px-10 pb-16 pt-6 mb-16"
            style={{ marginLeft: ml, marginRight: mr }}
        >
            <div className="w-full h-fit flex flex-row justify-center">
                {icon}
            </div>
            <h3 className="text-2xl max-sm:text-xl text-center font-Robotoserif font-bold pt-6 text-s-white break-words">
                {title}
            </h3>
            <p className="text-xl max-sm:text-lg text-left font-Roboto py-6 text-s-white">
                {text}
            </p>
            {
                <i>
                    <Link
                        to={linkPath}
                        className="text-xl text-s-bblue hover:text-s-bgreen font-Robotoserif"
                    >
                        {linkText}
                    </Link>
                </i>
            }
        </div>
    );
};
