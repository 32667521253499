import { useEffect } from 'react';
import { setLang } from './reducers/langReducer';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from './store';

export const LangSelector = () => {
    const [params] = useSearchParams();
    const lang = params.get('lang') ?? undefined;
    const dispatch = useAppDispatch();

    // Get lang on page reload
    useEffect(() => {
        dispatch(setLang(lang));
    }, []);

    return null;
};
