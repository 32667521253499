import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface LangState {
    lang?: string;
}

const initialState: LangState = {
    lang: undefined,
};

export const langSlice = createSlice({
    name: 'lang',
    initialState,
    reducers: {
        setLang(state, action: PayloadAction<string | undefined>) {
            state.lang = action.payload;
        },
    },
});

export const { setLang } = langSlice.actions;
export default langSlice.reducer;
