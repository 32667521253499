import React from 'react';

/**
 * Fullscreen banner image the size of one block
 */

interface VaaliPointProps {
    linkText: string;
    linkPath: string;
}

export const VaaliPoint: React.FC<VaaliPointProps> = ({
    linkText,
    linkPath,
}) => {
    return (
        <a
            href={linkPath}
            className="w-[280px] h-[5em] p-4 m-4 bg-s-dblue hover:bg-s-dblue/90 active:bg-s-dblue/80 transition-colors duration-100 rounded-2xl flex items-center justify-center"
        >
            <p className="text-xl max-sm:text-base font-Roboto text-center text-s-white">
                {linkText}
            </p>
        </a>
    );
};
