import React from 'react';
import { NavItem } from '../NavItem';
import classNames from 'classnames';

interface ItemProps {
    name: string;
    path: string;
    className?: string;
    innerClassName?: string;
    target?: string;
}

interface NavDropdownProps {
    name: string;
    open: boolean;
    setOpen: (b: boolean) => void;
    items: ItemProps[];
    closeAll: () => void;
}

export const NavDropdown: React.FC<NavDropdownProps> = ({
    name,
    items,
    open,
    setOpen,
    closeAll,
}) => {
    return (
        <div
            onClick={() => setOpen(!open)}
            className={classNames(
                'w-full h-fit flex flex-col justify-center relative cursor-pointer hover:bg-s-white/5 transition-colors duration-100'
            )}
        >
            <h3 className="p-8 text-left align-middle text-[22px] font-Roboto text-s-white">
                {name}
            </h3>

            {open && (
                <div className="w-full flex flex-col outline outline-1 outline-s-black/10">
                    {items.map((item) => (
                        <NavItem
                            key={item.name}
                            name={item.name}
                            path={item.path}
                            closeAll={closeAll}
                            className="h-min-[4.5em] w-full p-8 flex flex-col justify-center bg-s-gray hover:bg-s-white transition-colors duration-100"
                            innerClassName="text-left align-middle text-[22px] font-Roboto text-s-dblue"
                        />
                    ))}
                </div>
            )}
        </div>
    );
};
