import React from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/solid';

/**
 * Paragraph that can be expanded by the user.
 * Used to present text content more compactly on a page
 */

export interface ExpandableParagraphProps {
    title: string;
    children: JSX.Element;
}

export const ExpandableParagraph: React.FC<ExpandableParagraphProps> = ({
    title,
    children,
}) => {
    return (
        <Disclosure>
            {({ open }) => (
                <>
                    <Disclosure.Button className="flex flex-row items-center w-[90%] rounded-2xl px-2 py-4 mt-4 text-left text-xl font-Roboto font-bold transition-all hover:bg-s-bblue/40">
                        {open ? (
                            <ChevronUpIcon className="p-2 w-16 h-16 text-s-dgreen" />
                        ) : (
                            <ChevronDownIcon className="p-2 w-16 h-16 text-s-dgreen" />
                        )}
                        <span className="font-bold text-s-dgreen text-3xl max-md:text-2xl text-center pl-6 font-Roboto">
                            {title}
                        </span>
                    </Disclosure.Button>
                    <Transition
                        show={open}
                        unmount={false}
                        enterFrom="opacity-0 -translate-y-5"
                        enterTo="opacity-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0 -translate-y-5"
                        className="transition-all duration-200"
                    >
                        <Disclosure.Panel
                            unmount={false}
                            className="px-4 pt-2 pb-4 text-lg text-s-black font-Roboto"
                        >
                            {children}
                        </Disclosure.Panel>
                    </Transition>
                </>
            )}
        </Disclosure>
    );
};
